import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DragulaModule } from "ng2-dragula";
import { TranslateModule } from "@ngx-translate/core";
// Components
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { CustomizerComponent } from "./components/customizer/customizer.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { ContentComponent } from "./components/layout/content/content.component";
import { FullComponent } from "./components/layout/full/full.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";
// Header Elements Components
import { SearchComponent } from "./components/header/elements/search/search.component";
import { MegaMenuComponent } from "./components/header/elements/mega-menu/mega-menu.component";
import { LanguagesComponent } from "./components/header/elements/languages/languages.component";
import { NotificationComponent } from "./components/header/elements/notification/notification.component";
import { BookmarkComponent } from "./components/header/elements/bookmark/bookmark.component";
import { CartComponent } from "./components/header/elements/cart/cart.component";
import { MessageBoxComponent } from "./components/header/elements/message-box/message-box.component";
import { MyAccountComponent } from "./components/header/elements/my-account/my-account.component";
// Directives
import { DisableKeyPressDirective } from "./directives/disable-key-press.directive";
import { OnlyAlphabetsDirective } from "./directives/only-alphabets.directive";
import { OnlyNumbersDirective } from "./directives/only-numbers.directive";
import { ShowOptionsDirective } from "./directives/show-options.directive";
// Services
import { ChatService } from "./services/chat.service";
import { LayoutService } from "./services/layout.service";
import { NavService } from "./services/nav.service";
import { AvatarModule } from "ngx-avatar";
import { SpinnerComponent } from "./components/spinner/spinner/spinner.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { VkfDisProvizyonOlusturComponent } from "./services/dis-tedavi/vakif/vkf-dis-provizyon-olustur/vkf-dis-provizyon-olustur.component";
import { CapthcaComponent } from "./components/capthca/capthca/capthca.component";
import { TimerControlComponent } from "./timer-control/timer-control.component";
import { VkfTitleCardsComponent } from "./components/title-cards/vkf-title-cards/vkf-title-cards.component";
import { ShyTitleCardsComponent } from "./components/title-cards/shy-title-cards/shy-title-cards.component";
import { TrFormatPipe } from "./pipes/trFormat.pipe";
import { TevkifatLimitTutarTarihVakifComponent } from "./components/tevkifat-limit-tutar-tarih/tevkifat-limit-tutar-tarih-vakif/tevkifat-limit-tutar-tarih-vakif.component";
import { TevkifatLimitTutarTarihShyComponent } from "./components/tevkifat-limit-tutar-tarih/tevkifat-limit-tutar-tarih-shy/tevkifat-limit-tutar-tarih-shy.component";

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    CustomizerComponent,
    FeatherIconsComponent,
    FullComponent,
    ShowOptionsDirective,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    LoaderComponent,
    TapToTopComponent,
    SearchComponent,
    MegaMenuComponent,
    LanguagesComponent,
    NotificationComponent,
    BookmarkComponent,
    CartComponent,
    MessageBoxComponent,
    MyAccountComponent,
    SpinnerComponent,
    VkfDisProvizyonOlusturComponent,
    CapthcaComponent,
    TimerControlComponent,
    VkfTitleCardsComponent,
    ShyTitleCardsComponent,
    TrFormatPipe,
    TevkifatLimitTutarTarihVakifComponent,
    TevkifatLimitTutarTarihShyComponent,
  ],
  imports: [
    AvatarModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    DragulaModule.forRoot(),
    TranslateModule,
    NgxSpinnerModule,
  ],
  providers: [NavService, ChatService, LayoutService],
  exports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LoaderComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    TapToTopComponent,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    SpinnerComponent,
    CapthcaComponent,
    VkfTitleCardsComponent,
    ShyTitleCardsComponent,
    TevkifatLimitTutarTarihVakifComponent,
    TevkifatLimitTutarTarihShyComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
