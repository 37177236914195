import { Component, Input, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { AyaktaTedaviShyService } from "src/app/shared/services/ayaktaTedavi/ayaktaTedaviShy/ayakta-tedavi-shy.service";
import { ShyDisEmitService } from "src/app/shared/services/emitService/dis-tedavi/shy/shy-dis-emit.service";
import { OptikEmitService } from "src/app/shared/services/emitService/optik/optik-emit.service";
import { SiShyEmitService } from "src/app/shared/services/emitService/seansli-islemler/shy/si-shy-emit.service";
import { YtBaslikEmitService } from "src/app/shared/services/emitService/yatarak-tedavi/yt-baslik/yt-baslik-emit.service";
import { TarihFormatService } from "src/app/shared/services/genel/genel/TarihFormat.service";
import { HttpService } from "src/app/shared/services/http.service";
import { OrtakAyaktaTedaviVkfshyService } from "src/app/shared/services/ortakTanimlar/ortak-ayakta-tedavi-vkfshy.service";
import { OrtakTanimlarService } from "src/app/shared/services/ortakTanimlar/ortak-tanimlar.service";
import { ShyIcinOrtakService } from "src/app/shared/services/shyIcınOrtak/shy-icin-ortak.service";
import { TevkifatLimitTutarTarihEmitService } from "../../../services/emitService/tevkifat-limit-tutar-tarih-emit/tevkifat-limit-tutar-tarih-emit.service";

@Component({
  selector: "app-shy-title-cards",
  templateUrl: "./shy-title-cards.component.html",
  styleUrls: ["./shy-title-cards.component.scss"],
})
export class ShyTitleCardsComponent implements OnInit {
  avatarName = "resul";
  vipMi = true;

  provizyonId;
  islemTipId;
  @Input() doktorSGKAnlasmaliMi;
  type;
  constructor(
    private sioHttp: ShyIcinOrtakService,
    private shyHttp: AyaktaTedaviShyService,
    private emitServiceAt: OrtakAyaktaTedaviVkfshyService,
    private http: HttpService,
    private emitServiceYt: YtBaslikEmitService,
    private emitServiceSi: SiShyEmitService,
    private emitServiceDis: ShyDisEmitService,
    private ortakTanimlar: OrtakTanimlarService,
    private emitServiceOptik: OptikEmitService,
    private tarihFormat: TarihFormatService,
    private tevkifatLimitTutarTarihEmitService: TevkifatLimitTutarTarihEmitService
  ) {
    this.provizyonId = localStorage.getItem("provizyonId");
    this.islemTipId = localStorage.getItem("islemTipId");
    this.doktorSGKAnlasmaliMi = localStorage.getItem("sgk");
  }

  ngOnInit(): void {
    this.islemTipListesi();
    this.tevkifatLimitTutarTarihEmitService.getEmit.subscribe((res) => {
      this.getirEmit();
    });
  }
  stopService: Subscription;

  getirEmit() {
    switch (Number(this.type)) {
      case 1:
        this.emitServiceAt.getEmit.subscribe((res) => {
          this.provizyonGetir();
        });
        break;

      case 2:
        this.emitServiceOptik.getOptikEmit.subscribe((res) => {
          this.provizyonGetir();
        });
        break;
      case 3:
        this.emitServiceOptik.getOptikEmit.subscribe((res) => {
          this.provizyonGetir();
        });
        break;
      case 4:
        this.emitServiceDis.getEmit.subscribe((res) => {
          this.provizyonGetir();
        });
        break;

      case 9:
        this.emitServiceSi.getEmit.subscribe((res) => {
          this.provizyonGetir();
        });

        break;
      case 6:
        this.emitServiceYt.getEmit.subscribe((res) => {
          this.provizyonGetir();
        });
      case 7:
        this.emitServiceYt.getEmit.subscribe((res) => {
          this.provizyonGetir();
        });
      case 10:
        this.emitServiceYt.getEmit.subscribe((res) => {
          this.provizyonGetir();
        });
      case 11:
        this.emitServiceYt.getEmit.subscribe((res) => {
          this.provizyonGetir();
        });
        break;
      default:
        break;
    }
  }

  async islemTipListesi() {
    await this.sioHttp.islemTipListesi(this.provizyonId).then((res) => {
      this.type = res.data[0].islemTipID;

      this.getirEmit();
    });
  }
  provizyonGetirData;
  newVip;
  async provizyonGetir() {
    this.sioHttp.provizyonGetir(this.provizyonId).then((response) => {
      this.provizyonGetirData = response?.data;

      // this.newVip = response?.data.hastaModelDto?.vipAciklama.split("<br/>");
      this.newVip = response?.data.hastaModelDto?.vipAciklama.replace(
        /<br\/>/g,
        " "
      );

      this.kapsamGetirKontrol(
        this.provizyonGetirData.hastaModelDto.hastaTuru,
        this.provizyonGetirData.hastaModelDto.personelID,
        this.provizyonGetirData.hastaModelDto.akrabaID,
        this.provizyonGetirData.hizmetTarih
      );
      this.tevkifatKdvOranGetir(this.provizyonGetirData.hizmetTarih);
    });
  }

  kapsamData: any;
  kapsamGetirKontrol(hastaTuru, personelId, akrabaId, hizmetTarihi) {
    let d = new Date(hizmetTarihi);
    let tarih = this.tarihFormat.formatDate(d);

    akrabaId == null
      ? this.http
          .httpGet(
            `HastaShy/HastaPersonelKapsamBilgisiGetir?PersonelID=${personelId}&HizmetTarih=${tarih}`
          )
          .then((res) => {
            this.kapsamData = res?.data.kapsamBilgisi;
          })
      : this.http
          .httpGet(
            `HastaShy/HastaAkrabaKapsamBilgisiGetir?PersonelID=${personelId}&AkrabaID=${akrabaId}&HizmetTarih=${tarih}`
          )
          .then((res) => {
            this.kapsamData = res?.data.kapsamBilgisi;
          });
  }

  kdvOran;
  tevkifatOran;
  async tevkifatKdvOranGetir(tarih) {
    let d = new Date(tarih);
    let tarih1 = this.tarihFormat.formatDate(d);

    await this.ortakTanimlar
      .tevkifatKdvOraniGetir(
        tarih1,
        this.provizyonGetirData.provizyonFaturaDurumTanimID
      )
      .then((res) => {
        this.kdvOran = res?.data.kdvOran;
        this.tevkifatOran = res?.data.tevkifatOran;
      });
  }
}
