<div
  class="card"
  *ngIf="tevkifatGrupOran?.tevkifatLimitTutarTarihGuncelleGoster"
>
  <div class="theme-form">
    <div class="d-flex justify-content-between">
      <div style="margin-top: -10px">
        <h5 *ngIf="tevkifatLimitTutarTarihGuncelleFormVisible">
          Tevkifat Üst Sınır Değişimi
        </h5>
      </div>
      <div style="margin-top: -10px">
        <button
          color="primary"
          class="btn btn-outline-primary btn-upload"
          style="border: 1px solid #7196c8; min-width: 175px"
          (click)="tevkifatLimitTutarTarihGuncelleGosterBtn()"
        >
          <i
            [ngClass]="
              tevkifatLimitTutarTarihGuncelleFormVisible
                ? 'far fa-angle-up'
                : 'far fa-angle-down'
            "
            style="
              font-size: medium;
              color: #7196c8;
              font-size: 14px;
              font-weight: 600;
              letter-spacing: 0;
              line-height: 18px;
            "
            aria-hidden="true"
          ></i>
          Tevkifat Üst Sınır Değişimi
        </button>
      </div>
    </div>

    <div class="ux-hr res-mb-15" style="margin-top: 20px"></div>
    <div
      [@slideToggle]="
        tevkifatLimitTutarTarihGuncelleFormVisible ? 'open' : 'closed'
      "
      class="card-content"
    >
      <div class="w-100">
        <h4>Tevkifat limit değişimi olması halinde ;</h4>
        <h4>
          Hizmet tarihi itibari ile tevkifatlı olması gereken faturaların
          tevkifatsız duruma çekilmesi için düzenlenecek fatura tarihinin
          sisteme girilmesi ve güncellenmesi halinde provizyon tevkifatsız
          olacaktır.
        </h4>
      </div>
      <form
        [formGroup]="validationTevkifatTarihForm"
        class="needs-validation mt-3"
        novalidate
      >
        <div class="d-flex ux-external-line p-4 align-items-center">
          <div class="w-25 mr-4">
            <div class="form-group m-b-0">
              <label class="label"
                >Güncel Fatura Tarihi <b class="text-danger">*</b></label
              >
              <input
                type="date"
                [formControl]="
                  validationTevkifatTarihForm.controls['inputTevLimTutarTarih']
                "
                required
                (change)="changeTevLimTutarTarih($event.target.value)"
                placeholder="Tarih"
                class="form-control"
              />
            </div>
            <div
              *ngIf="
                validationTevkifatTarihForm.controls.inputTevLimTutarTarih
                  .touched &&
                validationTevkifatTarihForm.controls.inputTevLimTutarTarih
                  .errors?.required
              "
              class="text text-danger"
            >
              Tarih boş geçilemez!..
            </div>
          </div>
          <div class="mt-4 d-flex justify-content-center mr-4">
            <button
              class="btn btn-success"
              [disabled]="!validationTevkifatTarihForm.valid"
              (click)="tevkifatLimitTutarTarihGuncelleHandler()"
            >
              <span style="white-space: nowrap"
                >Tevkifat Limit Tarihine Göre Tekrar Hesapla</span
              >
            </button>
          </div>
          <div class="mt-4 d-flex justify-content-center">
            <button
              class="btn btn-danger"
              style="white-space: nowrap"
              (click)="tevkifatLimitTutarTarihGuncelleIptal()"
            >
              <span>Vazgeç</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>