import { Injectable } from "@angular/core";
import { HttpService } from "../http.service";

@Injectable({
  providedIn: "root",
})
export class OrtakTanimlarService {
  constructor(private http: HttpService) {}

  kurumProvizyonListesi(talepTurId) {
    return this.http.httpGet(
      `OrtakTanimlar/KurumProvizyonListesi?TalepTurTanimID=${talepTurId}`
    );
  }

  kurumProvizyonListesiHastaTuru(talepTurId, hastaTuru) {
    return this.http.httpGet(
      `OrtakTanimlar/KurumProvizyonListesi?TalepTurTanimID=${talepTurId}&HastaTuru=${hastaTuru}`
    );
  }

  kurumProvizyonListesiYatisDurumId(hastaTuru, yatisDurumId) {
    return this.http.httpGet(
      `OrtakTanimlar/KurumProvizyonListesi?TalepTurTanimID=5&HastaTuru=${hastaTuru}&ProvizyonYatisDurumID=${yatisDurumId}`
    );
  }
  kurumProvizyonListesiHepsiYatisDurumId(yatisDurumId) {
    return this.http.httpGet(
      `OrtakTanimlar/KurumProvizyonListesi?TalepTurTanimID=5&ProvizyonYatisDurumID=${yatisDurumId}`
    );
  }
  kurumProvizyonListesiHepsiDisDurumId(disDurumId) {
    return this.http.httpGet(
      `OrtakTanimlar/KurumProvizyonListesi?TalepTurTanimID=3&ProvizyonDisDurumID=${disDurumId}`
    );
  }

  kurumProvizyonListesiTc(talepTurId, tc) {
    return this.http.httpGet(
      `OrtakTanimlar/KurumProvizyonListesi?TalepTurTanimID=${talepTurId}&TCKimlikNo=${tc}`
    );
  }

  kurumProvizyonListesiFaturaSahibi(talepTurId, faturaSahibi) {
    return this.http.httpGet(
      `OrtakTanimlar/KurumProvizyonListesi?TalepTurTanimID=${talepTurId}&FaturaSahibiAdSoyad=${faturaSahibi}`
    );
  }
  kurumProvizyonListesiProvizyonNo(talepTurId, provizyonNo) {
    return this.http.httpGet(
      `OrtakTanimlar/KurumProvizyonListesi?TalepTurTanimID=${talepTurId}&ProvizyonID=${provizyonNo}`
    );
  }
  bransListesiVakifDis() {
    return this.http.httpGet(
      `OrtakTanimlar/BransListesiVakif?Ad=Di%C5%9F%20Hekimli%C4%9F`
    );
  }
  bransListesiShyDis() {
    return this.http.httpGet(
      `OrtakTanimlar/BransListesiVakif?Ad=Di%C5%9F%20Hekimli%C4%9F`
    );
  }
  bransListesiVakif() {
    return this.http.httpGet("OrtakTanimlar/BransListesiVakif");
  }

  islemTipListesi() {
    return this.http.httpGet(
      "OrtakTanimlar/IslemTipTanimListesi?TalepTurTanimID=5"
    );
  }

  bransListesiShy(tarih) {
    return this.http.httpGet(`OrtakTanimlar/BransListesiShy/${tarih}`);
  }

  icd10TanimGetir(id) {
    return this.http.httpGet(`OrtakTanimlar/ICD10TanimGetir/${id}`);
  }

  icd10TanimListesi(kodAd) {
    return this.http.httpGet(`OrtakTanimlar/ICD10TanimListesi?kodAd=${kodAd}`);
  }

  kurumAyaktaTedaviTeminatListesi(tarih) {
    return this.http.httpGet(
      `OrtakTanimlar/KurumAyaktaTedaviTeminatListesi/${tarih}`
    );
  }

  kurumSeansliIslemTeminatListesi(tarih) {
    return this.http.httpGet(
      `OrtakTanimlar/KurumSeansliIslemTeminatListesi/${tarih}`
    );
  }

  // odenenFaturaListesi() {
  //   return this.http.httpGet(`OrtakTanimlar/OdenenFaturaListesi?HastaTCNo=1&HastaAdSoyad=1&ProvizyonNo=1&FaturaNo=1&OdemeTarihiBas=1&OdemeTarihBit=1&SiralamaTuru=1`)
  // }
  odenenFaturaListesi() {
    return this.http.httpGet("OrtakTanimlar/OdenenFaturaListesi");
  }

  odenenFaturaListesiProvizyonNo(provizyonNo) {
    return this.http.httpGet(
      `OrtakTanimlar/OdenenFaturaListesi?ProvizyonNo=${provizyonNo}`
    );
  }
  odenenFaturaListesiTc(tc) {
    return this.http.httpGet(
      `OrtakTanimlar/OdenenFaturaListesi?HastaTCNo=${tc}`
    );
  }
  odenenFaturaListesiFaturaNo(faturaNo) {
    return this.http.httpGet(
      `OrtakTanimlar/OdenenFaturaListesi?FaturaNo=${faturaNo}`
    );
  }
  odenenFaturaListesiAdSoyad(adSoyad) {
    return this.http.httpGet(
      `OrtakTanimlar/OdenenFaturaListesi?HastaAdSoyad=${adSoyad}`
    );
  }
  odenenFaturaListesiPaketNo(paketNo) {
    return this.http.httpGet(
      `OrtakTanimlar/OdenenFaturaListesi?ProvizyonNo=${paketNo}`
    );
  }
  odenenFaturaListesiOdemeTarihiBaslangic(baslangicTarihi) {
    return this.http.httpGet(
      `OrtakTanimlar/OdenenFaturaListesi?OdemeTarihiBas=${baslangicTarihi}`
    );
  }
  odenenFaturaListesiOdemeTarihiBitis(bitisTarihi) {
    return this.http.httpGet(
      `OrtakTanimlar/OdenenFaturaListesi?OdemeTarihBit=${bitisTarihi}`
    );
  }
  odenenFaturaListesiOdemeSira(sira) {
    return this.http.httpGet(
      `OrtakTanimlar/OdenenFaturaListesi?SiralamaTuru=${sira}`
    );
  }
  odenenFaturaListesiOdemeBaslangicBitis(baslangic, bitis) {
    return this.http.httpGet(
      `OrtakTanimlar/OdenenFaturaListesi?OdemeTarihiBas=${baslangic}&OdemeTarihBit=${bitis}`
    );
  }
  odenenFaturaListesiHepsi(
    hastaTuru = "",
    sicil = "",
    hastaTCNo = "",
    adSoyad = "",
    faturaDurumTanimId = "",
    provizyonNo = "",
    faturaNo = "",
    provizyonPaketID = "",
    basTarih = "",
    bitTarih = "",
    siralamaTuru = 0
  ) {
    return this.http.httpGet(
      `OrtakTanimlar/OdenenFaturaListesi?HastaTuru=${hastaTuru}&Sicil=${sicil}&HastaTCNo=${hastaTCNo}&HastaAdSoyad=${adSoyad}&FaturaDurumTanimID=${faturaDurumTanimId}&ProvizyonNo=${provizyonNo}&FaturaNo=${faturaNo}&ProvizyonPaketID=${provizyonPaketID}&OdemeTarihiBas=${basTarih}&OdemeTarihBit=${bitTarih}&SiralamaTuru=${siralamaTuru}`
    );
  }
  // odenenFaturaListesiProvizyonNo(provizyonNo){
  //   return this.http.httpGet(`OrtakTanimlar/OdenenFaturaListesi?ProvizyonNo=${provizyonNo}`)
  // }

  //----------

  // odenenFaturaListesi(){
  //   return this.http.httpGet(`OrtakTanimlar/OdenenFaturaListesi`)
  // }
  // odenenFaturaListesi(){
  //   return this.http.httpGet(`OrtakTanimlar/OdenenFaturaListesi`)
  // }
  // odenenFaturaListesi(){
  //   return this.http.httpGet(`OrtakTanimlar/OdenenFaturaListesi`)
  // }
  // odenenFaturaListesi(){
  //   return this.http.httpGet(`OrtakTanimlar/OdenenFaturaListesi`)
  // }
  // odenenFaturaListesi(){
  //   return this.http.httpGet(`OrtakTanimlar/OdenenFaturaListesi`)
  // }
  // odenenFaturaListesi(){
  //   return this.http.httpGet(`OrtakTanimlar/OdenenFaturaListesi`)
  // }

  tevkifatKdvOraniGetir(tarih, provizyonFaturaDurumTanimID: number) {
    return this.http.httpGet(
      `OrtakTanimlar/TevkifatGrupOranlarGetir/${tarih}?provizyonFaturaDurumTanimID=${provizyonFaturaDurumTanimID}`
    );
  }

  kontrolBekleyenProvizyonListesi(value) {
    return this.http.httpGet(
      `OrtakTanimlar/KontrolBekleyenProvizyonListesi?provizyonFaturaDurumTanimID=${value}`
    );
  }

  kontrolBekleyenProvizyonListesiId(value, hastaTuru) {
    return this.http.httpGet(
      `OrtakTanimlar/KontrolBekleyenProvizyonListesi?HastaTuru=${hastaTuru}&provizyonFaturaDurumTanimID=${value}`
    );
  }

  kontrolBekleyenProvizyonListesiPaketDurumId(paketDurumId) {
    return this.http.httpGet(
      `OrtakTanimlar/KontrolBekleyenProvizyonListesi?provizyonFaturaDurumTanimID=${paketDurumId}`
    );
  }
  dosyaYuklemeProvizyonListesi(
    faturaSahibiAdSoyad,
    TCKimlikNo,
    provizyonId,
    hizmetTarihBas,
    hizmetTarihBit
  ) {
    return this.http.httpGet(
      `OrtakTanimlar/DosyaYuklemeProvizyonListesi?FaturaSahibiAdSoyad=${faturaSahibiAdSoyad}&TCKimlikNo=${TCKimlikNo}&ProvizyonID=${provizyonId}&HizmetTarihBas=${hizmetTarihBas}&HizmetTarihBit=${hizmetTarihBit}`
    );
  }

  dosyaYuklemeProvizyonListesiHastaTuru(
    hastaTuru,
    faturaSahibiAdSoyad,
    TCKimlikNo,
    provizyonId,
    hizmetTarihBas,
    hizmetTarihBit
  ) {
    return this.http.httpGet(
      `OrtakTanimlar/DosyaYuklemeProvizyonListesi?HastaTuru=${hastaTuru}&FaturaSahibiAdSoyad=${faturaSahibiAdSoyad}&TCKimlikNo=${TCKimlikNo}&ProvizyonID=${provizyonId}&HizmetTarihBas=${hizmetTarihBas}&HizmetTarihBit=${hizmetTarihBit}`
    );
  }
}
